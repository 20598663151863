























import { Component, Vue } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import {PageHeader} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import Channel from "./tabs/Channel.vue";
import IA from "./tabs/Ia.vue";
import ERPS from "./tabs/Erps.vue";
@Component({
  components: { PageHeader, Channel, IA, ERPS },
})
export default class Integrations extends Vue {
  pageHeader: TPageHeader = {
    mainIcon: "mdi-chip",
    titlePage: "Integrações",
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  entity = {};
  mounted() {
    this.entity = this.$route.params.entity;
  }
}
