


















































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { Schedule } from "../../types/Schedule";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import EventInput from "./EventInput.vue";
import { translate } from "../../plugins/i18n/index";
import ConfirmDelete from '../../components/ConfirmDelete.vue'
import ScheduleView from "./ScheduleView.vue";
import { paths } from "../../services/apiPaths";
import { format, parseISO } from "date-fns";
import AssociateEventAgent from "../../components/AssociateEventAgent.vue";
@Component({
  components: {
    List,
    EventInput,
    BaseBreadcrumb,
    ConfirmDelete,
    ScheduleView,
    AssociateEventAgent,
  },
})
export default class EventsList extends Request {
  path = paths.calendarRecorrencyPath;
  calendarId = null;
  title = `${translate("event")}`;

  customColumns = [
    {
      slotName: "recorrencyEvent.frequency",
      itemValue: "recorrencyEvent.frequency",
   
    },
  ];
  breadcrumbs = [
    {
      text: `${translate("calendar")}`,
      to: "/register/calendar-list/",
      disabled: false,
    },
    {
      text: `${translate("events")}`,
      disabled: true,
    },
  ];
  deletePath: string = "";

  header = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    {
      text: `${translate("registerDate")}`,
      align: "center",
      value: "createdAt",
    },
    {
      text: `${translate("frequency")}`,
      align: "center",
      value: "recorrencyEvent.frequency",
    },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-clock-outline",
    titlePage: `${translate("events")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Schedule[] = [];

  async loadData() {
    this.calendarId = this.$route.query.calendarId;

    this.deletePath = this.paths.calendarEventPath;
    const result = await this.get(this.paths.calendarEventPath);
    this.list = result[0];
    this.list = result[0].filter((e) => {
      if (e.calendarId == this.calendarId) return e;
    });
    if (result) {
      this.list.map((item) => {
        item.createdAt = format(parseISO(item.createdAt), "dd/MM/yyyy");
      });
    }
  }
  async mounted()  {
    this.loadData();
  }


}
