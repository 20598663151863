





























































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import Request from "../../services/request";
import TransferDialog from "../chat/TransferDialog.vue";
import CloseDialog from "../../components/CloseDialog.vue";
import ActiveDialog from "../../components/ActiveDialog.vue";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
import ManagerChat from "../manager/supervisor/lists/ManagerChat.vue";
import moment from "moment";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import {IUser} from "../../types/User";

import { HeaderItem } from "../../types/headeritem";
@Component({
  components: {
    List,
    TransferDialog,
    BaseBreadcrumb,
    ActiveDialog,
    CloseDialog,
    ManagerChat,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ServiceHistoryList extends Request {
  list: any[] = [];
  getUser!: () => IUser;
  user:IUser= {};
  itemsPerPage: number = 10;
  title: string = `${translate("ClosedCalls")}`;
  breadcrumbs = [
    {
      text: `${translate("ClosedCalls")}`,
      disabled: true,
    },
  ];
  deletePath: string = "";
  tagStatus = "status";
  entriesHistory: any = [];
  customColumns = [
    {
      slotName: "attendency.channelType",
      itemValue: "attendency.channelType",
    },
  ];

  header: HeaderItem[] = [
    {
      text: `${translate("client")}`,
      align: "start",
      value: "contact.firstName",
    },

    {
      text: `${translate("lastName")}`,
      align: "center",
      value: "contact.lastName",
    },
    //    {
    //      text: `${translate("email")}`,
    //      align: "center",
    //      value: "contactEmail.email",
    //    },
    {
      text: `${translate("protocol")}`,
      align: "center",
      value: "attendency.protocolNumber",
    },

    {
      text: `${translate("channelSingular")}`,
      align: "center",
      value: "attendency.channelType",
    },
    {
      text: `${translate("closingDate")}`,
      align: "center",
      value: "formattedEndDate",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "start",
      sortable: false,
      width: "15%",
    },
  ];
  search: string = "";
  searchHistory: any = null;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-account-multiple-remove",
    titlePage: `${translate("ClosedCalls")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async loadData() {
    this.deletePath = this.paths.personPath;
    this.user = this.getUser();

    const listWaiting = await this.get(this.paths.attendencyQueueGetAllPath, {
      take: this.itemsPerPage,
      skip: 1,
    });

    this.list = listWaiting[0]
      .filter((i) => i.endDate)
      .map((detail) => ({
        ...detail,
        formattedEndDate: moment(detail.endDate).format("DD/MM/YYYY"),
      }));
  }

  async mounted() {
    this.loadData();
  }

  get listWaiting() {
    return this.list;
  }
  // @Watch("searchHistory")
  async searchHistories(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.attendencyQueueGetAllPath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      this.entriesHistory = list.result[0];
    }
    return this.$emit("entries-company", this.entriesHistory);
  }
}
