
























































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Request from "@/services/request";
import { translate } from "../../plugins/i18n/index";
import { format } from "date-fns";

@Component({})
export default class DaysInput extends Request {
  @Prop() entity: any;
  @Prop() list: any;
  @Prop() isNotVisible: any;
  @Prop() loadData: any;
  @Prop() readonly calendarId: any;
  menu4: any = null;
  menu3: any = null;
  menu2: any = null;
  menu: any = null;

  timeEnd = "";
  timeStart = "";
  isActive = false;
  neverEndDate = "2050-02-28";
  neverStartTime = "00:00";
  neverEndeTime = "23:59";
  days = "";

  localEntityEvent: {
    event: {
      id?: 3;
      calendarId?: number;
      dateStart: string;
      dateEnd: string;
      neverEnd: boolean;
      name: string;
      description: string;
      workedDay: boolean;
    };
    recorrencyEvent: {
      frequency: string;
      interval: number;
      count: number;
      until: string;
      byDay: any;
      byMonth: any;
      byYear: string;
      byHour: string;
    };
    eventParticipants?: any;
  } = {
    event: {
      dateStart: "",
      dateEnd: "",
      name: "",
      description: "",
      neverEnd: false,
      workedDay: false,
    },
    recorrencyEvent: {
      frequency: "",
      interval: 0,
      count: 0,
      until: "",
      byDay: "",
      byMonth: "",
      byYear: "",
      byHour: "",
    },
    eventParticipants: [],
  };
  users: any = [];
  listAgents: any = [];
  byDayList = [
    { id: 1, name: `${translate("sunday")}`, value: "SU" },
    { id: 2, name: `${translate("monday")}`, value: "MO" },
    { id: 3, name: `${translate("tuesday")}`, value: "TU" },
    { id: 4, name: `${translate("wednesday")}`, value: "WE" },
    { id: 5, name: `${translate("thursday")}`, value: "TR" },
    { id: 6, name: `${translate("friday")}`, value: "FR" },
    { id: 7, name: `${translate("saturday")}`, value: "ST" },
  ];
  byMonthList = [
    { id: 1, name: `${translate("january")}`, value: "JA" },
    { id: 2, name: `${translate("february")}`, value: "FE" },
    { id: 3, name: `${translate("March")}`, value: "MA" },
    { id: 4, name: `${translate("april")}`, value: "AP" },
    { id: 5, name: `${translate("may")}`, value: "MA" },
    { id: 6, name: `${translate("june")}`, value: "JU" },
    { id: 7, name: `${translate("july")}`, value: "JL" },
    { id: 8, name: `${translate("august")}`, value: "AU" },
    { id: 9, name: `${translate("september")}`, value: "SE" },
    { id: 10, name: `${translate("october")}`, value: "OC" },
    { id: 11, name: `${translate("november")}`, value: "NV" },
    { id: 12, name: `${translate("december")}`, value: "DC" },
  ];
  periodList = [
    { id: 1, name: `${translate("yearly")}`, value: "Y" },
    { id: 2, name: `${translate("monthly")}`, value: "M" },
    { id: 3, name: `${translate("weekly")}`, value: "W" },
    { id: 4, name: `${translate("diary")}`, value: "D" },
  ];
  event: any = {};
  eventDetail = { workedDay: true };
  participants = { personId: "1", groupId: "1" };
  recorrency: boolean = false;
  recorencyDetail = {};
  dateEndFormatted = "";
  dateStartFormatted = "";
  async onSave() {
    await this.newList();
    return this.$emit("update-dialog", false);
  }
  load(): void {
    if (this.entity.id) {
      this.localEntityEvent.event.id = this.entity.id;
      this.localEntityEvent.event.name = this.entity.name;
      this.localEntityEvent.event.description = this.entity.description;
      this.localEntityEvent.event.dateStart = new Date(this.entity.dateStart)
        .toISOString()
        .substr(0, 10);
      this.localEntityEvent.event.dateEnd = new Date(this.entity.dateEnd)
        .toISOString()
        .substr(0, 10);
      this.timeStart = format(new Date(this.entity.dateStart), "HH:mm");
      this.timeEnd = format(new Date(this.entity.dateEnd), "HH:mm");
      this.localEntityEvent.event.workedDay = this.entity.workedDay;

      if (this.entity.recorrencyEventId) {
        this.recorrency = true;
        this.localEntityEvent.recorrencyEvent = this.entity.recorrencyEvent;
      }

      if (this.entity.eventParticipants.length > 0) {
        this.localEntityEvent.eventParticipants =
          this.entity.eventParticipants.map((element) => element.userId);
      }
    }
  }

  async newList() {
    if (this.localEntityEvent.recorrencyEvent.byDay) {
      const days = this.localEntityEvent.recorrencyEvent.byDay.toString();
      this.localEntityEvent.recorrencyEvent.byDay = `[${days}]`;
    }
    if (this.localEntityEvent.recorrencyEvent.byMonth) {
      const months = this.localEntityEvent.recorrencyEvent.byMonth.toString();
      this.localEntityEvent.recorrencyEvent.byMonth = `[${months}]`;
    }
    if (this.recorrency && this.localEntityEvent.recorrencyEvent.frequency) {
      if (this.localEntityEvent.recorrencyEvent.frequency === "W") {
        this.localEntityEvent.recorrencyEvent.interval = 7;
      } else if (this.localEntityEvent.recorrencyEvent.frequency === "D") {
        this.localEntityEvent.recorrencyEvent.interval = 1;
      } else if (this.localEntityEvent.recorrencyEvent.frequency === "Y") {
        this.localEntityEvent.recorrencyEvent.interval = 365;
      } else if (this.localEntityEvent.recorrencyEvent.frequency === "M") {
        this.localEntityEvent.recorrencyEvent.interval = 31;
      }
      this.localEntityEvent.recorrencyEvent.count = Number(
        this.localEntityEvent.recorrencyEvent.count
      );
    } else {
      delete this.localEntityEvent.recorrencyEvent;
    }
    if (this.localEntityEvent.event.neverEnd === true) {
      this.localEntityEvent.event.dateStart =
        this.localEntityEvent.event.dateStart + `T${this.timeStart}`;
      this.localEntityEvent.event.dateEnd =
        this.neverEndDate + `T${this.timeEnd}`;
    } else {
      this.localEntityEvent.event.dateStart =
        this.localEntityEvent.event.dateStart + `T${this.timeStart}`;
      this.localEntityEvent.event.dateEnd =
        this.localEntityEvent.event.dateEnd + `T${this.timeEnd}`;
    }

    this.localEntityEvent.event.calendarId = this.calendarId;

    if (this.localEntityEvent.eventParticipants.length > 0) {
      this.localEntityEvent.eventParticipants =
        this.localEntityEvent.eventParticipants.map((element) => {
          return {
            userId: element,
            organizer: true,
          };
        });
    }

    const result = await this.save(
      this.paths.eventFullPath,
      this.localEntityEvent
    );

    if (!this.localEntityEvent.event.id) {
      this.localEntityEvent = {
        event: {
          dateStart: "00-00-00",
          dateEnd: "00-00-00",
          name: " ",
          description: "",
          neverEnd: false,
          workedDay: false,
        },
        recorrencyEvent: {
          frequency: "",
          interval: 0,
          count: 0,
          until: "",
          byDay: "",
          byMonth: "",
          byYear: "",
          byHour: "",
        },
      };
      (this.timeEnd = "00:00"), (this.timeStart = "00:00");
    }
    this.loadData();
    return result;
  }
  formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day.slice(0, 2)}/${month}/${year}`;
  }
  @Watch("localEntityEvent.event.dateStart")
  formartStart(date) {
    this.dateStartFormatted = this.formatDate(date);
  }
  @Watch("localEntityEvent.event.dateEnd")
  formartEnd(date) {
    this.dateEndFormatted = this.formatDate(date);
  }
  async mounted() {
    this.load();
    const users = await this.get(this.paths.userAgentPath);
    this.listAgents = users[0];

      this.localEntityEvent.recorrencyEvent.byMonth.toString().split(",");
    if (this.localEntityEvent.recorrencyEvent.byMonth) {
      
      let months = this.localEntityEvent.recorrencyEvent.byMonth;
      months = months.toString().split(",");
      for (let i = 0; i < months.length; i++) {
        months[i] = months[i].replace("[", "");
        months[i] = months[i].replace("]", "");
      }

      this.localEntityEvent.recorrencyEvent.byMonth = months;
    }

    if (this.localEntityEvent.recorrencyEvent.byDay) {
      this.localEntityEvent.recorrencyEvent.byDay.toString().split(",");

      let days = this.localEntityEvent.recorrencyEvent.byDay;

      days = days.toString().split(",");
      for (let i = 0; i < days.length; i++) {
        days[i] = days[i].replace("[", "");
        days[i] = days[i].replace("]", "");
      }

      this.localEntityEvent.recorrencyEvent.byDay = days;
    }
  }
  closeDialog() {
    return this.$emit("update-dialog", false);
  }
  parseDate(date) {
    if (!date) return null;

    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
  get saveDisabled() {
    return (
      this.missingName ||
      this.missingStartDate ||
      this.missingUsers ||
      this.missingRepeatAt
    );
  }

  get missingName() {
    return !this.localEntityEvent.event.name;
  }
  get missingStartDate() {
    return !this.localEntityEvent.event.dateStart;
  }

  get missingUsers() {
    return !this.localEntityEvent.eventParticipants;
  }
  get missingRepeatAt() {
    if (this.recorrency) {
      return !this.localEntityEvent.recorrencyEvent.frequency;
    }
    return false;
  }
}
