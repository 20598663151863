








































































































import { Component, Vue } from "vue-property-decorator";
import Stepper from "../Stepper.vue";
@Component({
  components: {
    Stepper,
  },
})
export default class Channel extends Vue {
  entity = {};
  mounted() {
    this.entity = this.$route.params.entity;
  }
}
