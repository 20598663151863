




















import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import FinancialInput from "./FinancialInput.vue";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { ConfirmDelete, BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { format, parseISO } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';

@Component({
  components: {
    FinancialInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
}) 
export default class FinancialList extends Request {
  path = paths.financialPath;

  title = `${translate("financial")}`;

  breadcrumbs = [
    {
      text: `${translate("financial")}`,
      disabled: true,
    },
  ];

  header = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("active")}`, align: "center", value: "active" },
    { text: `${translate("updatedAt")}`, align: "center", value: "updatedAt" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-currency-usd",
    titlePage: `${translate("financial")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: any[] = [];

  formatData(data: any) {
    data[0].map((item: any) => {
    
      let itemns = format(item.updatedAt,"L",{ locale: ptBR });
      item.updatedAt = itemns;
    });
  }
  async loadData() {
    const result = await this.get(this.paths.financialPath);
    this.formatData(result);
    if (result) {
      const result = await this.get(this.paths.financialPath);
      result[0].map((product) => {
        product.updatedAt = format(parseISO(product.updatedAt),"dd/MM/yyyy");
      });
      this.list = result[0];
    }
  }
  async mounted()  {
    this.loadData();
  }
}
