var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.isNotVisible,"outlined":"","label":_vm.$t('name'),"placeholder":_vm.$t('name'),"rules":[_vm.$rules.fieldsRequired]},model:{value:(_vm.localEntityEvent.event.name),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "name", $$v)},expression:"localEntityEvent.event.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('description')},model:{value:(_vm.localEntityEvent.event.description),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "description", $$v)},expression:"localEntityEvent.event.description"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.localEntityEvent.event.dateStart,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntityEvent.event, "dateStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntityEvent.event, "dateStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('startDate'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateStartFormatted),callback:function ($$v) {_vm.dateStartFormatted=$$v},expression:"dateStartFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.localEntityEvent.event.dateStart),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "dateStart", $$v)},expression:"localEntityEvent.event.dateStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.localEntityEvent.event.dateStart)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":_vm.$t('neverEnds'),"color":"primary"},model:{value:(_vm.localEntityEvent.event.neverEnd),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "neverEnd", $$v)},expression:"localEntityEvent.event.neverEnd"}})],1),(_vm.localEntityEvent.event.neverEnd == false)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.localEntityEvent.event.dateEnd,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntityEvent.event, "dateEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntityEvent.event, "dateEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('endDate'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,340709298),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.localEntityEvent.event.dateEnd),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "dateEnd", $$v)},expression:"localEntityEvent.event.dateEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.localEntityEvent.event.dateEnd)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu3",attrs:{"disabled":_vm.isNotVisible,"close-on-content-click":false,"return-value":_vm.timeStart,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.timeStart=$event},"update:return-value":function($event){_vm.timeStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.isNotVisible,"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('startTime'),"prepend-inner-icon":"mdi-clock","readonly":""},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-time-picker',{attrs:{"scrollable":"","format":"24hr","ampm":"true"},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu3.save(_vm.timeStart)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.timeEnd,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.timeEnd=$event},"update:return-value":function($event){_vm.timeEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('endTime'),"prepend-inner-icon":"mdi-clock","readonly":""},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-time-picker',{attrs:{"scrollable":"","format":"24hr","ampm":"true"},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu4 = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu4.save(_vm.timeEnd)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":_vm.$t('dayWorked'),"color":"primary"},model:{value:(_vm.localEntityEvent.event.workedDay),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.event, "workedDay", $$v)},expression:"localEntityEvent.event.workedDay"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":_vm.$t('recorrency'),"color":"primary"},model:{value:(_vm.recorrency),callback:function ($$v) {_vm.recorrency=$$v},expression:"recorrency"}})],1),(_vm.recorrency)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"disabled":_vm.isNotVisible,"outlined":"","items":_vm.periodList,"label":_vm.$t('frequency'),"placeholder":_vm.$t('frequency'),"item-text":"name","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.localEntityEvent.recorrencyEvent.frequency),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.recorrencyEvent, "frequency", $$v)},expression:"localEntityEvent.recorrencyEvent.frequency"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"disabled":_vm.isNotVisible,"outlined":"","type":"number","label":_vm.$t('repeatAt'),"placeholder":_vm.$t('repeatAt')},model:{value:(_vm.localEntityEvent.recorrencyEvent.count),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.recorrencyEvent, "count", $$v)},expression:"localEntityEvent.recorrencyEvent.count"}})],1),(_vm.localEntityEvent.recorrencyEvent.frequency === 'W')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"disabled":_vm.isNotVisible,"outlined":"","items":_vm.byDayList,"label":_vm.$t('byDay'),"placeholder":_vm.$t('byDay'),"item-text":"name","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired],"multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.localEntityEvent.recorrencyEvent.byDay),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.recorrencyEvent, "byDay", $$v)},expression:"localEntityEvent.recorrencyEvent.byDay"}})],1):_vm._e(),(_vm.localEntityEvent.recorrencyEvent.frequency === 'M')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.byMonthList,"label":_vm.$t('month'),"placeholder":_vm.$t('month'),"item-text":"name","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired],"multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.localEntityEvent.recorrencyEvent.byMonth),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.recorrencyEvent, "byMonth", $$v)},expression:"localEntityEvent.recorrencyEvent.byMonth"}})],1):_vm._e(),(_vm.localEntityEvent.recorrencyEvent.frequency === 'Y')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['20##']),expression:"['20##']"}],attrs:{"value":"20","outlined":"","label":_vm.$t('at'),"placeholder":_vm.$t('at'),"rules":[_vm.$rules.fieldsRequired],"type":"number"},model:{value:(_vm.localEntityEvent.recorrencyEvent.byYear),callback:function ($$v) {_vm.$set(_vm.localEntityEvent.recorrencyEvent, "byYear", $$v)},expression:"localEntityEvent.recorrencyEvent.byYear"}})],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.listAgents,"label":_vm.$t('selectParticipants'),"item-text":"user.firstName","multiple":"","item-value":"userId"},model:{value:(_vm.localEntityEvent.eventParticipants),callback:function ($$v) {_vm.$set(_vm.localEntityEvent, "eventParticipants", $$v)},expression:"localEntityEvent.eventParticipants"}})],1)],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.saveDisabled},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }