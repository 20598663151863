













































import { Component, Prop } from "vue-property-decorator";
import InputPopup from "@matrixbrasil/matrix-lib-crud-frontend/src/views/layout/InputPopup.vue";
import { Schedule } from "../../types/Schedule";
import Request from "@/services/request";
import { translate } from "../../plugins/i18n/index";
import Days from "./Days.vue";
@Component({
  components: {
    InputPopup,
    Days,
  },
})
export default class EventInput extends Request {
  @Prop() readonly entity: Schedule;
  @Prop() readonly list: Schedule[];
  @Prop() readonly isNotVisible: any;
  @Prop() readonly loadData: any;
  @Prop() readonly calendarId: any;

  dialog: boolean = false;

  localEntity: any = {};
  updateDialog(value) {
    this.dialog = value;
  }
  header = `${translate("event")}`;
}
