





















import { Component, Prop } from "vue-property-decorator";
import InputPopup from "@matrixbrasil/matrix-lib-crud-frontend/src/views/layout/InputPopup.vue";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { Schedule } from "../../types/Schedule";
import Request from "@/services/request";
import { translate } from "../../plugins/i18n/index";
import Days from "./Days.vue";
@Component({
  components: {
    InputPopup,
    Days,
  },
})
export default class ScheduleInput extends Request {
  @Prop() readonly entity: Schedule;
  @Prop() readonly list: Schedule[];
  @Prop() readonly isNotVisible: any;

  dialog: boolean = false;

  updateDialog(value) {
    this.dialog = value;
  }
  header = `${translate("event")}`;

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 12,
      slot: "days",
    },
  ];
}
