









































































































import { Component, Prop } from "vue-property-decorator";
import { Financial } from "../../types/Financial";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { format, parseISO } from "date-fns";

@Component({
  components: {},
})
export default class FinancialInput extends Request {
  @Prop() readonly entity: Financial;
  @Prop() readonly list: Financial[];
  @Prop() loadData: any;
  readonly header = `${translate("financial")}`;

  dialog: boolean = false;
  localEntity: any = {};

  active: any = null;
  name: string = "";
  description: string | undefined = "";
  scope: string = "";
  logo: string | undefined = "";
  token: string = "";

  async onSave()  {
    this.dialog = false;
    return await this.newList();
  }

  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }

  async newList() {
    Object.assign(this.entity, this.localEntity);
    this.entity.name = this.name;
    this.entity.description = this.description;
    this.entity.active = this.active;
    this.entity.scope = this.scope;
    this.entity.token = this.token;

    const result = await this.save(this.paths.financialPath, this.entity);
    result.updatedAt = format(parseISO(result.updatedAt), "dd/MM/yyyy");
    this.loadData();

    this.localEntity = {};
    return result;
  }

  async mounted() {
    this.name = this.entity.name;
    this.description = this.entity.description;
    this.active = this.entity.active;
    this.scope = this.entity.scope;
    this.token = this.entity.token;
    this.load();
  }

  get missingName() {
    return !this.name;
  }
  get missingToken() {
    return !this.token;
  }
  get saveDisabled() {
    return this.missingName || this.missingToken;
  }
}
