














































































































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class Stepper extends Vue {
  e1: number = 1;
  dialog: boolean = false;
}
